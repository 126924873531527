import React, { useState, useEffect } from 'react';
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import InputField from '../components/InputField'; 
import Modal from '../components/Modal';
// import IsLoading from './IsLoading';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in
    const accessToken = Cookies.get('accessToken');
    const userRole = Cookies.get('userRole');
    const userId = Cookies.get('userId');
    const userStatus = Cookies.get('userStatus');

    console.log("Client ID", process.env.REACT_APP_GOOGLE_CLIENT_ID);
    console.log("Backend URL", process.env.REACT_APP_BACKEND_URL); 
    console.log('Cookies set:', {
      accessToken,
      userRole,
      userId,
      userStatus
    });

    if (userStatus === 'accepted') {
      if (accessToken && userRole && userId) {
        if (userRole === 'student') {
          window.location.href = '/student-dashboard';
        } else if (userRole === 'teacher' || userRole === 'admin') {
          console.log("role is", userRole);
          window.location.href = '/teacher-dashboard';
        } else {
          console.log("role is", userRole);
        }
      }
    }
  }, []);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try{
        const decodedToken = jwtDecode(credentialResponse?.credential);
        console.log(decodedToken);
        Cookies.set("email", decodedToken.email);

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login-google`, {
          token: credentialResponse.credential
        });
        
        console.log(response);
        
        if (response.data.newUser) {
          navigate(`/signup?email=${decodedToken.email}&firstName=${decodedToken.given_name}&lastName=${decodedToken.family_name}`);
        } else {
          Cookies.set("accessToken", response.data.token);
          Cookies.set("userRole", response.data.user_role);
          Cookies.set("userStatus", response.data.user_status);

          if (response.data.user_status === 'accepted') {
            if (response.data.user_role === 'admin' || response.data.user_role === 'teacher') {
              console.log('Redirecting to teacher dashboard', response.data.user_role);
              navigate("/teacher-dashboard");
            } else if (response.data.user_role === 'examinee') {
              console.log('Redirecting to examinee dashboard', response.data.user_role);
              navigate("/student-dashboard");
            }
          } else {
            setShowModal(true);
          }
        }
    } catch (error) { 
        console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Step 2: Check if fields are filled
    if (!username || !password) {
      setError('Both username and password are required.'); // Set error message
      return; // Stop the function from proceeding further
    }
    setError(''); // Clear any previous errors if the form is correctly filled

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
          username, 
          password
      });

      // console.log("Login response:", response.data);

      console.log("Login response:", {
        accessToken: response.data.access_token,
        userRole: response.data.user_role,
        userId: response.data.user_id,
        userStatus: response.data.user_status
      });

      if (response.data.access_token && response.data.user_role && response.data.user_id && response.data.user_status) {
        Cookies.set('accessToken', response.data.access_token);
        Cookies.set('userRole', response.data.user_role);
        Cookies.set('userId', response.data.user_id);
        Cookies.set('email', response.data.email);
        Cookies.set('userStatus', response.data.user_status);
      }

      console.log('Cookies set:', {
        accessToken: Cookies.get('accessToken'),
        userRole: Cookies.get('userRole'),
        userId: Cookies.get('userId'),
        userStatus: Cookies.get('userStatus')
      });

      if (response.data.user_status === 'accepted') {
        if (response.data.user_role === 'admin' || response.data.user_role === 'teacher') {
          console.log('Redirecting to teacher dashboard');
          window.location.href = '/teacher-dashboard';
        } else if (response.data.user_role === 'examinee') {
          console.log('Redirecting to student dashboard');
          window.location.href = '/student-dashboard';
        } else {
          console.log("role is", response.data.user_role);
        }
      } else {
        console.log("User status is", response.data.user_status);
        setShowModal(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Clear the cookies if the login is invalid
        Cookies.remove('accessToken');
        Cookies.remove('userRole');
        Cookies.remove('userId');
        Cookies.remove('userStatus');

        setError(error.response?.data?.error || 'Invalid username or password.');
      } else {
      setError(error.response?.data?.error || 'An error occurred.');
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    Cookies.remove('accessToken');
    Cookies.remove('userRole');
    Cookies.remove('userId');
    Cookies.remove('userStatus');
  }

  return (
    <div className="flex flex-col min-h-screen w-screen align-middle justify-center items-center bg-body"> 
        <h1 className="text-3xl font-bold text-center text-maroon mb-6">Specialized Online Assessment Platform - Prototype</h1>

        {/* Step 3: Display the error message if it exists */}
        {error && <div className="text-red-500 text-center mb-4">{error}</div>}

        <form className="flex flex-col mt-8 space-y-8 w-1/3" style={{ maxWidth: "590px", minWidth: "300px" }} onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-1 w-full">
            <InputField type="text" label="Username" value={username} onChange={e => setUsername(e.target.value)} placeholder="e.g. juandelacruz"/>
            <InputField type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} placeholder="********" />
          </div>
          
          <div className='flex w-full justify-center align-middle'>
            <button type="submit" className="group relative justify-center py-3 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-maroon hover:bg-red-950 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Login
            </button>
          </div>
          <div className="flex w-full justify-center align-middle mt-4">
            <span>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={handleGoogleLoginSuccess}
                onError={() => {
                console.error('Login failed');
                }}
              />
            </span>
          </div>
          <div className="flex text-sm items-center justify-center align-middle">
            <a href="/signup" className="font-medium text-maroon hover:text-indigo-500">
              Create an account
            </a>
          </div>
        </form>
        {showModal && (
        <Modal
          onClose={handleCloseModal}
          closeText="Close"
        >
          <div className="flex flex-col items-center justify-center p-4">
            <p>Your account is not yet verified by the admin.</p>
          </div>
        </Modal>
      )}  
    </div>
  );
}

axios.interceptors.request.use( function(config) {
  const accessToken = Cookies.get('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

export default LoginPage;
