import React from 'react';
import Cookies from 'js-cookie';

const Header = ({ isTeacher, currentPage, isLogoutBlocked }) => {

    const handleLogout = () => {
        // Add logout functionality here
        // Clear all cookies
        Cookies.remove('accessToken');
        Cookies.remove('userRole');
        Cookies.remove('userId');
        Cookies.remove('email');
        Cookies.remove('token');
        
        // Redirect to login page
        window.location.href = '/login';
    }


  return (
    <div className="flex justify-between items-center w-full h-1/2 bg-maroon px-4" style={{ height: '58px', position:'sticky', top: 0, zIndex:1000 }}>
      <div className="flex">
        <h1 className="text-xl font-bold text-body">Soap Prototype</h1>
        {isTeacher && <div className="flex text-white font-semibold mx-6 mt-0.5 space-x-6">
            {/* <div className={`${currentPage=='dashboard' && 'bg-white text-maroon px-2 rounded-md'}`}>
              <a href="/teacher-dashboard"> Dashboard </a>
            </div> */}
            <div className='flex flex-col'>
              <a href="/teacher-dashboard"> Dashboard </a>
              {currentPage=='dashboard' && 
                <hr className='bg-white h-0.5 rounded-lg'/>
              }
            </div>
            <div className='flex flex-col'>
              <a href="/users"> Users </a>
              {currentPage=='users' && 
                <hr className='bg-white h-0.5 rounded-lg'/>
              }
            </div>
          </div>}
      </div>
      {!isLogoutBlocked && <button onClick={handleLogout} className="py-0.5 rounded-md px-3 bg-white text-maroon border text-sm font-semibold border-maroon hover:bg-gray-200">
        Logout
      </button>
}
    </div>
  );
};

export default Header;