import React, { useState } from 'react';

const StatusFilter = ({ onStatusChange }) => {
    const [selectedStatus, setSelectedStatus] = useState([]);

    const handleStatusClick = (status) => {
        let updatedStatus;
        if (selectedStatus.includes(status)) {
            updatedStatus = selectedStatus.filter((s) => s !== status);
        } else {
            updatedStatus = [...selectedStatus, status];
        }
        setSelectedStatus(updatedStatus);
        onStatusChange(updatedStatus);

    };

    const buttonClass = (status) => 
        selectedStatus.includes(status)
            ? "bg-maroon text-white border-maroon"
            : "bg-light-gray text-dark-gray border-light-gray";

    return (
        <div className="flex space-x-2">

            <button
                onClick={() => handleStatusClick('Not Taken')}
                className={`border rounded-md p-2 text-md ${buttonClass('Not Taken')}`}
            >
                Not Taken
            </button>
            <button
                onClick={() => handleStatusClick('In Progress')}
                className={`border rounded-md p-2 text-md ${buttonClass('In Progress')}`}
            >
                In Progress
            </button>
            <button
                onClick={() => handleStatusClick('To Evaluate')}
                className={`border rounded-md p-2 text-md ${buttonClass('To Evaluate')}`}
            >
                To Evaluate
            </button>
            <button
                onClick={() => handleStatusClick('Flagged')}
                className={`border rounded-md p-2 text-md ${buttonClass('Flagged')}`}
            >
                Flagged
            </button>
            <button
                onClick={() => handleStatusClick('Safe')}
                className={`border rounded-md p-2 text-md ${buttonClass('Safe')}`}
            >
                Safe
            </button>
        </div>
    );
};

export default StatusFilter;
