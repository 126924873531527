import React, { useState, useEffect } from "react";

const Snackbar = ({ message, duration, onClose }) => {
    const [visible, setVisible] = useState(false);
    const [animationClass, setAnimationClass] = useState("");

    useEffect(() => {
        if (message) {
            setVisible(true);
            setAnimationClass("fade-in");

            const timer = setTimeout(() => {
                setAnimationClass("fade-out");
                setTimeout(() => {
                    setVisible(false);
                    onClose();
                }, 500); // Match the duration of the fade-out animation
            }, duration || 1500);

            return () => clearTimeout(timer);
        }
    }, [message, duration, onClose]);

    return (
        visible && (
            <div className={`fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-maroon text-white font-semibold px-4 py-2 rounded shadow-lg ${animationClass}`}>
                {message}
            </div>
        )
    );
};

export default Snackbar;