import React from 'react';

function InputField({ type, label, value, onChange, textColor='dark-gray', placeholder, disabled, ...rest }) {
  return (
    <div className="mb-4"> 
      <label htmlFor={type} className={`block text-sm font-medium text-${textColor}`}>
        {label}
      </label>
      <input
        type={type}
        id={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder} // Added placeholder prop usage
        disabled={disabled}
        className={`form-control w-full border-6 border-light-gray py-2 px-3 text-dark-gray rounded-md ${
          disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white' }`}
        {...rest}
      />
    </div>
  );
}

export default InputField;
