import React, {useState, useEffect} from "react";
import Header from "../components/Header";
import Modal from "../components/Modal";
import axios from "axios";
import Cookies from 'js-cookie';
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";

const Users = () => {

    const [users, setUsers] = useState([]);
    const [showApproveModal, setshowApproveModal] = useState(false);
    const [showRejectModal, setshowRejectModal] = useState(false);
    const [showPhotoPopup, setShowPhotoPopup] = useState(false); 
    const [currentPhotoUrl, setCurrentPhotoUrl] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const entriesPerPage = 10;
    const [totalPages, setTotalPages] = useState(Math.ceil(users.length / entriesPerPage));
    const navigate = useNavigate();


    useEffect(() => {
        const userRole = Cookies.get('userRole');
        if (userRole === undefined) {
            navigate('/login');
        } else if (userRole === 'examinee') {
            navigate('/student-dashboard');
        } else if (userRole === 'teacher') {
            fetchStudents();
        } else if (userRole === 'admin') {
            fetchUsers();
        }
    }, [])

    const handleApprove = (userId) => {
        const accessToken = Cookies.get('accessToken');
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/approve/${userId}`,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            // Handle success (e.g., update UI or show a success message)
            console.log("User approved successfully", response);
            // Optionally, refresh the users list to reflect the change
            fetchUsers(); // Assuming you have a function to fetch users
        })
        .catch((error) => {
            // Handle error
            console.error("Error approving user", error);
        });
        setshowApproveModal(false);
    }

    

    const handleReject = (userId) => {
        const accessToken = Cookies.get('accessToken');
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/reject/${userId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            // Handle success (e.g., update UI or show a success message)
            console.log("User approved successfully", response);
            // Optionally, refresh the users list to reflect the change
            fetchUsers(); // Assuming you have a function to fetch users
        })
        .catch((error) => {
            // Handle error
            console.error("Error approving user", error);
        });
        setshowRejectModal(false);
    }

    const fetchUsers = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/status/pending`,{
            headers: {
                Authorization: `Bearer ${Cookies.get('accessToken')}`
            }
        })
        .then((res) => {
            console.log(res.data)
            setUsers(res.data)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    const fetchStudents = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/status/pending`,{
            headers: {
                Authorization: `Bearer ${Cookies.get('accessToken')}`
            }
        })
        .then((res) => {
            console.log(res.data)
            // loop through the array of users and filter out the students
            const students = res.data.filter(user => user.user_role === 'examinee');
            setUsers(students)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    const handleViewPhoto = (url) => {
        const fullUrl = `${process.env.REACT_APP_BACKEND_URL}/${url}`;
        setCurrentPhotoUrl(fullUrl);
        setShowPhotoPopup(true);
    };

    const filteredUsers = users.filter(user => {
        const matchesSearchTerm = 
            `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.student_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesSearchTerm;
    });

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Assuming the popup has a unique ID or class, e.g., 'popup'
            if (showPhotoPopup && !document.getElementById('popup').contains(event.target)) {
                setShowPhotoPopup(false);
            }
        };

        if (showPhotoPopup) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showPhotoPopup]); // Dependency array ensures this effect runs only when showPhotoPopup changes

    useEffect(() => {
        setTotalPages(Math.max(1, Math.ceil(filteredUsers.length / entriesPerPage)));
      }, [filteredUsers.length]);

    const paginatedUsers = filteredUsers.slice((page - 1) * entriesPerPage, page * entriesPerPage);
    

    return (
        <div className="flex flex-col bg-body w-screen h-min-screen">
            <Header currentPage='users' isTeacher={true}/>
            <div className="flex flex-col min-h-screen w-screen h-full items-center">
                <h1 className="flex w-full text-2xl font-bold text-maroon justify-center py-10">
                    Pending Users
                </h1>
                <div className="flex flex-col w-2/3 align-middle justify-center ">
                    <div className="flex items-center justify-center align-middle w-full mb-6">
                        <input
                            type="text"
                            placeholder="Search user"
                            className="border border-light-gray rounded-md p-2 text-md w-full"
                            style={{ maxWidth: "1200px" }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {users.length>0 ? <table className="divide-y divide-light-gray">
                        <thead>
                            <tr>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Student/Employee Number
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Role
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Email
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-light-gray">
                            {paginatedUsers.map((user) => (
                                <tr key={user.id}>
                                    <td className="whitespace-nowrap px-3 py-4 text-md font-semibold text-dark-gray ">
                                    {user.employee_number ? user.employee_number : user.student_number}
                                    </td>
                                    <td className="whitespace-nowrap pl-3 pr-6 py-4 text-md text-dark-gray  text-left">
                                    {user.first_name} {user.last_name}
                                    </td>
                                    <td className="whitespace-nowrap pl-3 pr-6 py-4 text-md text-dark-gray">
                                    {user.user_role}
                                    </td>
                                    <td className="whitespace-nowrap pl-3 pr-6 py-4 text-md text-dark-gray">
                                    {user.email}
                                    </td>
                                    <td className="whitespace-nowrap px-10 py-4 text-sm font-semibold text-maroon">
                                    {user.profile_picture && <a href="#" onClick={() => handleViewPhoto(user.profile_picture)} className="hover:opacity-70"> View Uploaded Image </a>}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray">
                                    <button className="bg-green-button px-4 py-1 font-semibold mx-6 rounded-md text-body hover:opacity-70"
                                        onClick={() => {setSelectedUser(user); setshowApproveModal(true)}}
                                    >
                                        Approve
                                    </button>
                                    <button className="bg-maroon px-6 py-1 font-semibold mx-6 rounded-md text-body hover:opacity-70"
                                        onClick={() => {setSelectedUser(user); setshowRejectModal(true)}}
                                    >
                                        Reject
                                    </button>
                                    </td>   
                                </tr>
                            ))}
                        </tbody>
                    </table> : `No Pending Users`}
                    <div className="flex w-full items-center justify-center align-middle py-6">
                    <Pagination page={page} setPage={setPage} totalPages={totalPages} />
                    </div>
                </div>
                
            </div>
            {showApproveModal && (
                <Modal
                    onClose={() => setshowApproveModal(false)}
                    closeText="Cancel"
                    actionButton="Approve"
                    onAction={() => handleApprove(selectedUser._id)}
                    actionColor="green-button"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Do you want to accept this user's registration request?</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                    By confirming you are, approving this user's registration request and confirming that the uploaded image is the person itself and understand that this action cannot be undone.
                    </p>
                </Modal>
            )}
            {showRejectModal && (
                <Modal
                    onClose={() => setshowRejectModal(false)}
                    closeText="Cancel"
                    actionButton="Reject"
                    onAction={() => handleReject(selectedUser._id)}
                    actionColor="maroon"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Do you want to reject this user's registration request??</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                        By confirming you are, rejecting this user's registration request and understand that this action cannot be undone.
                    </p>
                </Modal>
            )}
            {showPhotoPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div id="popup" className="relative bg-white p-4 rounded-lg overflow-auto" style={{ height: '40vw', maxWidth: '100%', maxHeight: '100%' }} >
                        <img src={currentPhotoUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Users;