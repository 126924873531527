import React from "react";

function ExamStatus({ examStatus }) {
  const statusColors = {
    "Not Taken": "bg-light-gray text-black",
    "In Progress": "bg-gold text-black",
    "Processing": "bg-lighter-gray text-black",
    "To Evaluate": "bg-dark-gray text-white",
    "Flagged": "bg-maroon text-white", // Adjusting red to match reference
    "Safe": "bg-green-button text-white",
  };

  const statusClass = statusColors[examStatus] || "bg-gray-400 text-white"; // Default to gray if status is unknown

  return (
    <span
      className={`inline-flex items-center rounded-md text-l py-0.5 font-medium ${statusClass} align-middle justify-center`}
      style={{ width: "115px", height: "24px", borderRadius: "8px" }}
    >
      {examStatus}
    </span>
  );
}

export default ExamStatus;