import React, { useState, useEffect } from "react";
import ExamStatus from "./ExamStatus";
import { useNavigate } from "react-router-dom";
import StatusFilter from "./StatusFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Pagination from "./Pagination";

const ExamineeTable = ({ examinees }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const entriesPerPage = 10;
    const [totalPages, setTotalPages] = useState(Math.ceil(examinees.length / entriesPerPage));
    const viewAnalysis = (result_id) => {
        navigate(`/analytics/${result_id}`);
    };

    const handleStatusChange = (statuses) => {
        setSelectedStatuses(statuses);
    }

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const getStatusOrder = (status) => {
        const order = {
            "Not Taken": 1,
            "In Progress": 2,
            "To Evaluate": 3,
            "Flagged": 4,
            "Safe": 5,
        };
        return order[status] || 5;
    };

    const sortedExaminees = [...examinees].sort((a, b) => {
        if (sortColumn) {
            let aValue = a[sortColumn];
            let bValue = b[sortColumn];

            if (sortColumn === "score") {
                const aIsNA = aValue === null || aValue === undefined;
                const bIsNA = bValue === null || bValue === undefined;
    
                if (aIsNA && bIsNA) return 0; // Both are "N/A", considered equal
                if (aIsNA) return 1; // a is "N/A", it should be sorted last
                if (bIsNA) return -1; // b is "N/A", it should be sorted last
    
                // If neither is "N/A", convert to numbers for comparison
                aValue = +aValue;
                bValue = +bValue;

            } else if (sortColumn === "status") {
                aValue = getStatusOrder(aValue);
                bValue = getStatusOrder(bValue);
            } else {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
            if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
            return 0;
        }
        return 0;
    });


    const filteredExaminees = sortedExaminees.filter((examinee) => {
        const matchesSearchTerm = 
            `${examinee.first_name.toLowerCase()} ${examinee.last_name.toLowerCase()}`.includes(searchTerm.toLowerCase()) ||
            examinee.student_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            examinee.email.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesStatus = selectedStatuses.length === 0 || selectedStatuses.includes(examinee.status);

        return matchesSearchTerm && matchesStatus;
    });

    useEffect(() => {
        setTotalPages(Math.max(1, Math.ceil(filteredExaminees.length / entriesPerPage)));
      }, [filteredExaminees.length]);

    const paginatedExaminees = filteredExaminees.slice((page - 1) * entriesPerPage, page * entriesPerPage);


    const getIconClass = (column, order) => {
        if (sortColumn === column) {
            return order === sortOrder ? "opacity-100" : "hidden";
        }
        return "opacity-25";
    };
    return(
        <>
      {/* horizontal div for search bar, filter by status (component) */}
      <div className="flex justify-between items-center mb-4 w-screen align-middle" style={{maxWidth:"1200px"}}>
        <div className="flex items-center justify-center align-middle w-screen space-x-4">
          <input
            type="text"
            placeholder="Search examinee"
            className="border border-light-gray rounded-md p-2 text-md w-full"
            style={{ maxWidth: "600px" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          < StatusFilter onStatusChange={handleStatusChange}/>
          </div>
      </div>
        <div className="flex flex-column w-screen h-full justify-center">
        <table className="divide-y divide-light-gray w-2/3" style={{ maxWidth: "1200px", tableLayout: "fixed" }}>
            <thead className="font-bold">
                <tr>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                        style={{ width: "20%" }}
                        onClick={() => handleSort("student_number")}
                    >
                        Student Number
                        {sortColumn === "student_number" ? (
                            <FontAwesomeIcon
                                icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                                className="ml-2 opacity-100"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowUp}
                                className="ml-2 opacity-25"
                            />
                        )}
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                        style={{ width: "20%" }}
                        onClick={() => handleSort("first_name")}
                    >
                        Name
                        {sortColumn === "first_name" ? (
                            <FontAwesomeIcon
                                icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                                className="ml-2 opacity-100"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowUp}
                                className="ml-2 opacity-25"
                            />
                        )}
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                        style={{ width: "20%" }}
                        onClick={() => handleSort("email")}
                    >
                        Email
                        {sortColumn === "email" ? (
                            <FontAwesomeIcon
                                icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                                className="ml-2 opacity-100"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowUp}
                                className="ml-2 opacity-25 z-10"
                            />
                        )}
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                        style={{ width: "20%" }}
                        onClick={() => handleSort("status")}
                    >
                        Status
                        {sortColumn === "status" ? (
                            <FontAwesomeIcon
                                icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                                className="ml-2 opacity-100"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowUp}
                                className="ml-2 opacity-25"
                            />
                        )}
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                        style={{ width: "20%" }}
                        onClick={() => handleSort("score")}
                    >
                        Cheating %
                        {sortColumn === "score" ? (
                            <FontAwesomeIcon
                                icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                                className="ml-2 opacity-100"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faArrowUp}
                                className="ml-2 opacity-25"
                            />
                        )}
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-light-gray">
                {paginatedExaminees.map((examinee) => (
                    <tr key={examinee.id}>
                        <td
                            className="whitespace-nowrap px-3 py-3.5 text-md font-medium text-dark-gray"
                            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {examinee.student_number}
                        </td>
                        <td
                            className="whitespace-nowrap px-3 py-4 text-md text-dark-gray"
                            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {examinee.first_name} {examinee.last_name}
                        </td>
                        <td
                            className="whitespace-nowrap px-3 py-4 text-md text-dark-gray"
                            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {examinee.email}
                        </td>
                        <td
                            className="whitespace-nowrap px-3 py-4 text-md text-dark-gray"
                            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            <ExamStatus examStatus={examinee.status} />
                        </td>
                        <td
                            className="whitespace-nowrap px-3 py-4 text-md text-dark-gray"
                            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {examinee.score ? `${Math.round(examinee.score)}%` : 'N/A'}
                            {(examinee.status === 'To Evaluate' || examinee.status === 'Flagged' || examinee.status === 'Safe') ? (
                                <button className="text-sm text-green-button ml-10" onClick={() => viewAnalysis(examinee.result_id)}>
                                    View Analysis
                                </button>
                            ) : (
                                <div className="text-sm ml-3 opacity-0">
                                    View Analysis
                                </div>
                            )}
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>

        </div>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </>
    );

};

export default ExamineeTable;