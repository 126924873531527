/* global cv */
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import Header from "../components/Header";
import Modal from "../components/Modal";
import { useParams } from "react-router-dom";
import RecordRTC from 'recordrtc';
import Loading from "./Loading";

export default function ExamProper() {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const { result_id } = useParams();
    const [result, setResult] = useState({});
    const [recorder, setRecorder] = useState(null);
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [examStarted, setExamStarted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [screenshotIntervalId, setScreenshotIntervalId] = useState(null);
    const [isContentAvailable, setIsContentAvailable] = useState(false);
    const elapsedTimeRef = useRef(0);  // Ref to store the latest elapsed time


    const handleOpenModal = () => setIsModalOpen(true);
    const screenshotSecondsInterval = 3;

    const handleDoneExam = () => {
        setLoading(true);
        clearInterval(screenshotIntervalId);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/exam-done/${result_id}`)
            .then(response => {
                console.log('Exam done', response.data);
            })
            .catch(error => {
                console.error('Error processing exam done', error);
            });

        if (recorder) {
            recorder.stopRecording(async () => {
                const blob = recorder.getBlob();
                const formData = new FormData();
                formData.append('file', blob, `video_${Date.now()}.webm`);
                formData.append('result_id', result_id);

                try {
                    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/results/video`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log('Video uploaded successfully');
                    // setLoading(false);
                    window.location.href = '/student-dashboard';
                } catch (error) {
                    console.error('Error uploading video', error);
                }
            });
        }
    };

    const startWebcamFeed = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined
                }
            });
            const video = videoRef.current;
            if (video) {
                video.srcObject = stream;
                video.onloadedmetadata = () => {
                    video.play(); // Webcam feed starts playing immediately
                };
            }
        } catch (err) {
            console.error("Error accessing the camera:", err);
            if (err.name === "NotAllowedError") {
                alert("Camera access is not allowed. Please enable camera permissions in your browser settings.");
            } else {
                alert("Could not access the camera. Please ensure you have a camera available and have granted permission to use it.");
            }
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/get_result_details/${result_id}`)
            .then(response => {
                console.log('Result', response.data);
                setResult(response.data);
            })
            .catch(error => {
                console.error('Error fetching result', error);
            });

        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
                setVideoDevices(videoInputDevices);
                if (videoInputDevices.length > 0) {
                    setSelectedDeviceId(videoInputDevices[0].deviceId);
                }
            })
            .catch(error => {
                console.error('Error fetching video devices', error);
            });
        startWebcamFeed();
    }, [result_id]);

    const startRecordingAndScreenshots = () => {
        const video = videoRef.current;
    
        // Start recording
        const stream = video.srcObject;
        const options = {
            type: 'video',
            mimeType: 'video/webm',
            disableLogs: true
        };
        const recorderInstance = new RecordRTC(stream, options);
        recorderInstance.startRecording();
        setRecorder(recorderInstance);
    
        // Start taking screenshots
        const screenshotInterval = setInterval(() => {
            takeScreenshot();
        }, screenshotSecondsInterval * 1000);
        setScreenshotIntervalId(screenshotInterval);
    };
    

    const startExam = () => {
        setExamStarted(true);
        // open a new tab with the exam_link
        let examLink = result.exam_link;
        if (!examLink.startsWith('http://') && !examLink.startsWith('https://')) {
            examLink = `http://${examLink}`;
        }
        // Open a new tab with the examLink
        window.open(examLink);
        // Start the timer
        const startTime = Date.now();
        const timer = setInterval(() => {
            const elapsed = Math.floor((Date.now() - startTime) / 1000);
            setElapsedTime(elapsed);
            elapsedTimeRef.current = elapsed;  // Update the ref
        }, 1000);
    
        // Start video recording and screenshot-taking
        startRecordingAndScreenshots();
    
        return () => clearInterval(timer); // Ensure cleanup of timer
    };
    

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        const seconds = totalSeconds - hours * 3600 - minutes * 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    

    const takeScreenshot = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
    
        // Set canvas dimensions to match the video dimensions
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
    
        // Draw the video frame onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
        // Convert the canvas content to a blob and upload it
        canvas.toBlob(blob => {
            const formData = new FormData();
            const timestamp = formatTime(elapsedTimeRef.current);
            console.log("Timestamp: ", timestamp)
            formData.append('file', blob, `screenshot_${Date.now()}.png`);
            formData.append('result_id', result_id);
            formData.append('timestamp', timestamp);
    
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/results/screenshot`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log('Screenshot uploaded successfully', response.data);
            }).catch(error => {
                console.error('Error uploading screenshot', error);
            });
        }, 'image/png');
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col w-screen min-h-screen bg-body">
            <Header isLogoutBlocked={true} />
            <div className="body flex flex-col w-screen h-full py-16 items-center space-y-10 ">
                <h1 className="text-2xl font-bold text-maroon">{result ? result.title: ''}</h1>
                {examStarted && 
                    (<div className="flex space-x-6">
                        <h1 className="bg-maroon rounded-lg p-2 text-white font-semibold">
                            Elapsed Time: {formatTime(elapsedTime)}
                        </h1>
                    </div>
                )}
                <>
                    <video ref={videoRef} style={{ display: 'inline' }} width="640" height="480"></video>
                    <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
                </>
                {!examStarted && (
                    <div className="flex flex-col items-center space-y-4">
                        {/* {isContentAvailable ? (
                            <>
                                <video ref={videoRef} style={{ display: 'inline' }} width="640" height="480"></video>
                                <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
                            </>
                        ) : (
                            <div className="bg-gray-400" style={{ height: "480px", width: "600px" }}></div>
                        )} */}

                        <button 
                            className="bg-maroon text-white font-bold p-2 rounded-lg" 
                            onClick={startExam}
                        >
                            Start Exam
                        </button>
                    </div>
                )}
                {examStarted && (
                    <div className="flex flex-col items-center space-y-4">
                        <button className="bg-maroon text-white font-bold p-2 rounded-lg" onClick={handleOpenModal}> Done </button>
                    </div>
                )}
            </div>
            {isModalOpen && (
                <Modal
                    onAction={handleDoneExam}
                    actionButton='Yes'
                    actionColor='green-button'
                    actionColorText='body'
                    onClose={() => setIsModalOpen(false)}
                >
                    <h1 className='text-lg my-4 font-semibold'>
                        Is your exam done?
                    </h1>
                    <hr />
                    <h1 className='text-lg my-4'>
                        By clicking "Yes", you are confirming that you are done with your exam and understand that this action cannot be undone.
                    </h1>
                </Modal>
            )}
        </div>
    );
}
