export default function Loading() {
    return (
        <>
            <style>
                {`
                    @keyframes loading {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }

                    body {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100vh;
                        background-color: #F5F5F5; 
                    }
                `}
            </style>
            <div style={{
                height: '50px',
                width: '50px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'progress',
                borderRadius: '50%',
                borderTop: '5px solid #801730', // Changed to #801730
                borderBottom: '5px solid transparent',
                borderLeft: '5px solid #801730', // Changed to #801730
                borderRight: '5px solid transparent',
                animation: 'loading 1s linear infinite',
            }}></div>
        </>
    );
}