import React, { useRef, useState, useEffect } from "react";
import Header from "../components/Header";
import BackButton from "../components/BackButton";
import Modal from "../components/Modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../pages/Loading";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const Analytics = () => {

    const { result_id } = useParams()
    const [result, setResult] = useState(null);
    const [videoUrl, setVideoUrl] = useState("");
    const [showCheatedModal, setShowCheatedModal] = useState(false);
    const [showSafeModal, setShowSafeModal] = useState(false);
    const [showPhotoPopup, setShowPhotoPopup] = useState(false); 
    const [currentPhotoUrl, setCurrentPhotoUrl] = useState(``);
    const [resultJson, setResultJson] = useState(null);
    const timestamps = ['0:12', '0:58', '1:22', '2:32', '3:15'];
    const [loading, setLoading] = useState(true);
    const videoRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const userRole = Cookies.get('userRole');
        const userId = Cookies.get('userId');

        if (!userId || !userRole) {
            navigate('/login');
            return;
        }

        const checkAuthorization = async () => {
            try {
                console.log("Checking authorization...", userId, result_id);
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/check-authorization`, {
                    params: { userId: userId, resultId: result_id },
                    headers: {
                        Authorization: `Bearer ${Cookies.get('accessToken')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.data.authorized) {
                    if (userRole === undefined) {
                        navigate('/login');
                    } else if (userRole === 'examinee') {
                        navigate('/student-dashboard');
                    }
                    } else if (userRole === 'teacher') {
                        navigate('/teacher-dashboard');
                    }
                
            } catch (error) {
                console.error("Authorization check failed", error);
                if (userRole === undefined) {
                    navigate('/login');
                } else if (userRole === 'examinee') {
                    navigate('/student-dashboard');
                } else if (userRole === 'teacher') {
                    navigate('/teacher-dashboard');
                }
            }
        };

        checkAuthorization();

    }, [navigate, result_id]);
    

    // Convert timestamp string to seconds
    const timestampToSeconds = (timestamp) => {
        const parts = timestamp.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseInt(parts[2], 10);
        return (hours * 3600) + (minutes * 60) + seconds;
    };

    // Seek video to timestamp
    const seekToTimestamp = (timestamp) => {
        const seconds = timestampToSeconds(timestamp);
        if (videoRef.current) {
            videoRef.current.currentTime = seconds;
        }
    };

    const handleFlagAsCheated = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/flag-result/${result_id}`)
            .then((response) => {
                console.log("Flagged as cheated");
            })
            .catch((error) => {
                console.error("Error flagging as cheated:", error);
            });
        window.location.href = "/teacher-dashboard";
        setShowCheatedModal(false);
    };

    const handleFlagAsSafe = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/unflag-result/${result_id}`)
            .then((response) => {
                console.log("Flagged as cheated");
            })
            .catch((error) => {
                console.error("Error flagging as cheated:", error);
            });
        window.location.href = "/teacher-dashboard";
        setShowSafeModal(false);
    };

    const handleViewPhoto = (url) => {
        const fullUrl = `${process.env.REACT_APP_BACKEND_URL}/${url}`;
        setCurrentPhotoUrl(fullUrl);
        setShowPhotoPopup(true);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/get_result_details/${result_id}`)
            .then((res) => {
                console.log("Fetch Done");
                console.log(res.data);
                setResult(res.data);
                if (res.data.result_out_path) {
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/${res.data.result_out_path}`)
                        .then((response) => {
                            console.log("Fetched result.json");
                            setResultJson(response.data);
                        })
                        .catch((error) => {
                            console.error("Error fetching result.json:", error);
                        });
                }
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })
            .catch((error) => {
                console.error(error);
            });
            setTimeout(() => {
                setLoading(false);
            }, 500);
            
    },[]);

    useEffect(() => {
        if (result && result.video_path) {
            const url = `${process.env.REACT_APP_BACKEND_URL}/${result.video_path}`;
            setVideoUrl(url);
        }
    }, [result]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Assuming the popup has a unique ID or class, e.g., 'popup'
            if (showPhotoPopup && !document.getElementById('popup').contains(event.target)) {
                setShowPhotoPopup(false);
            }
        };

        if (showPhotoPopup) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showPhotoPopup]); // Dependency array ensures this effect runs only when showPhotoPopup changes

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        const strTime = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    if (loading) {
        return <Loading />; // Render a loading state while fetching data
    }

    return(
        <div className="flex flex-col bg-body w-screen min-h-screen">
            
            <Header isTeacher={true} currentPage={'dashboard'}/>
            <div className="body flex flex-col w-screen h-2/3 py-16 items-center space-y-10 ">
                <div className="flex justify-between w-screen">
                    <div className="w-1/3">
                        <BackButton />
                    </div>
                    <div className="w-1/3">
                        <h1 className="flex w-full text-2xl font-bold text-maroon justify-center">
                            Analysis
                        </h1>
                    </div>
                    <div className="w-1/3"></div>
                </div>
                {result ? result.title: ''}
                <div className="flex w-1/3 h-fit bg-maroon rounded-2xl text-wrap">
                    <div className="text-white my-5 w-1/2">
                        <div className="flex mx-4 space-x-3">
                            <p> Student Number: </p>
                            <h1 className="font-bold"> {result ? result.student_number: ''} </h1>
                        </div>
                        <div className="flex mx-4 space-x-3">
                            <p> Name: </p>
                            <h1 className="font-bold"> {result ? result.first_name: ''} {result ? result.last_name: ''} </h1>
                        </div>
                        <div className="flex mx-4 space-x-3">
                            <p> Email: </p>
                            <h1 className="font-bold"> {result ? result.email: ''} </h1>
                        </div>
                        <div className="flex mx-4 space-x-3">
                            <p> Exam start: </p>
                            <h1 className="font-bold"> {result ? formatDate(result.start_time): ''} </h1>
                        </div>
                        <div className="flex mx-4 space-x-3">
                            <p> Exam end: </p>
                            <h1 className="font-bold"> {result ? formatDate(result.submission_time): ''} </h1>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center align-middle items-center my-5 mx-3 w-1/2 text-white">
                        <h1 className="text-3xl">Cheating %</h1>
                        <h1 className="text-3xl font-bold">{result ? `${Math.round(result.score)}%` : ''}</h1>
                    </div>
                </div>
                <button onClick={() => handleViewPhoto(result.profile_picture)} className="text-blue-400 italic hover:opacity-70">View Uploaded Image</button>
                {/* <p>Number of malicious instances detected: <strong>{}</strong></p> */}
                <div className="flex flex-row w-full max-h-screen justify-center align-middle items-center space-y-6">
                    <div className="video-container mx-10">
                        <video width="960" height="540" controls ref={videoRef}>
                            <source src={`${process.env.REACT_APP_BACKEND_URL}/${result.video_path}`} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="flex flex-col space-x-3 font-semibold border rounded-lg border-maroon p-4  text-maroon" style={{ overflowY: 'auto', height: 540}}>
                    {resultJson && resultJson.map((entry, index) => (
                        entry.message !== "" && (
                            <div className="flex space-x-3" key={index} onClick={() => seekToTimestamp(entry.time)}>
                                <p style={{ cursor: 'pointer' }}>{entry.time}</p>
                                <p className="text-dark-gray">{entry.message}</p>
                            </div>
                        )
                    ))}
                    </div>
                </div>
                <div className="flex space-x-10 font-medium">
                    <button onClick={() => setShowCheatedModal(true)}>
                        <a className="text-lg text-white bg-maroon px-4 py-2 rounded-md">FLAG AS CHEATED</a>
                    </button>
                    <button onClick={() => setShowSafeModal(true)}>
                        <a className="text-lg text-white bg-green-button px-4 py-2 rounded-md">FLAG AS SAFE</a>
                    </button>
                </div>
            </div>
            {showCheatedModal && (
                <Modal
                    onClose={() => setShowCheatedModal(false)}
                    closeText="Cancel"
                    actionButton="Confirm"
                    onAction={handleFlagAsCheated}
                    actionColor="maroon"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Do you want to flag {result.first_name} {result.last_name}'s' exam as cheating?</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                        By confirming you are, reporting this exam as cheating. This flag can be edited later on.
                    </p>
                </Modal>
            )}
            {showSafeModal && (
                <Modal
                    onClose={() => setShowSafeModal(false)}
                    closeText="Cancel"
                    actionButton="Confirm"
                    onAction={handleFlagAsSafe}
                    actionColor="green-button"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Do you want to flag Mark Otto’s exam as not cheating?</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                        By confirming you are, indicating that these results are not the result of cheating. This flag can be edited later on.
                    </p>
                </Modal>
            )}
            {showPhotoPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div id="popup" className="relative bg-white p-4 rounded-lg overflow-auto" style={{ height: '40vw', maxWidth: '100%', maxHeight: '100%' }} >
                        <img src={currentPhotoUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Analytics;