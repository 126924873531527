import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ExamTable from '../components/ExamTable';
import Modal from '../components/Modal';
import DragDropFiles from '../components/DragDropFiles';
import axios from 'axios';
import Cookies from 'js-cookie';
import Loading from './Loading';
import Snackbar from '../components/Snackbar';

function StudentDashboard() {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [student, setStudent] = useState(null);
    const [exams, setExams] = useState([]);
    const [studentId, setStudentId] = useState('');
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        // Get token and email from cookies
        if (!Cookies.get('token')) {
            if (!Cookies.get('accessToken')) {
                window.location.href = '/login';
                return;
            }  
        }

        console.log(Cookies.get("userRole"));
        if (Cookies.get('userRole') === 'teacher') {
            window.location.href = '/teacher-dashboard';
            return;
        }
        const token = Cookies.get('token') ? Cookies.get('token') : Cookies.get('accessToken');
        const email = Cookies.get('email');
        
        console.log('Email from Cookies:', {email});

        if (email) {
            // fetch student data using email
            const fetchStudentData = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/email/${email}`,{
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    console.log(response.data);
                    setStudentId(response.data._id);

                    setStudent(response.data);
                    if (!response.data.profile_picture) {
                        setIsUploadModalOpen(true);
                    }
                    
                } catch (error) {
                    if (error.response) {
                        console.error("Response error:", error.response.data);
                        if (error.response.status === 401) {
                            Cookies.remove('token');
                            Cookies.remove('email');
                            window.location.href = '/login';
                        }
                    } else if (error.request) {
                        console.error("Request error:", error.request);
                        console.error("Request details:", {
                            readyState: error.request.readyState,
                            status: error.request.status,
                            statusText: error.request.statusText,
                            responseURL: error.request.responseURL,
                            responseText: error.request.responseText
                        });
                    } else {
                        console.error("Error:", error.message);
                }
            }
            };

            
            fetchStudentData();
           
        } else {
            // Redirect to login page if user is not logged in
            window.location.href = '/login';
            console.error("Error: else error");
            return;
        }
    }, []);
    
    const fetchExams = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/examinees/${studentId}/exams`);
            setExams(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Cookies.remove('token');
                window.location.href = '/login';
            } else {
                setLoading(false); // Set loading to false after data is fetched
                console.error("Error fetching exams:", error);
            }
        }
    };
    
    // fetch exams when student changes
    useEffect(() => {
        fetchExams();
    }, [student]);


    const handlePhotoUpload = (uploadedPhoto) => {
        if (!photo) {
            setPhoto(uploadedPhoto);
            // Convert the uploaded file to a URL for preview
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(uploadedPhoto);
        }
    };

    const handleUploadClick = () => {
        console.log("Photo uploaded:", photo);
        
    };

    const handleCancelClick = () => {
        setPhoto(null);
        setIsUploadModalOpen(false);
    };

    // Drag & Drop CSV File
    const [files, setFiles] = useState(null);

    function handleDrop(event) {
        event.preventDefault();
        // if the uploaded is not a photo, do not set the photo
        if (!event.dataTransfer.files[0].type.includes('image')) {
            console.error('Invalid file type');
        }
        else{
            setPhoto(event.dataTransfer.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(event.dataTransfer.files[0]);

        }
    }

    const handleUpload = (event) => {
        if (!photo) {
            const uploadedFile = event.target.files[0];
            handlePhotoUpload(uploadedFile);
        }
    };


    const dragDropFilesProps = {
        handleDrop: handleDrop,
        handleUpload: handleUpload,
        files: photo,
        setFiles: setPhoto
    };

    const handleCreateExamResult = (exam_id) => {
        const newResult = {
            examinee_id: studentId,
            exam_id: exam_id,
            exam_status: "Not Taken"
        };
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/create_result`, newResult)
            .then((res) => {
                console.log("Exam result created successfully:", res.data);
                setSnackBarMessage('Exam added successfully!'); // Show success message in Snackbar
                fetchExams(); // Re-fetch exams after successful creation
            })
            .catch((error) => {
                console.error("Error creating exam result:", error);
                if (error.response && error.response.status === 409) {
                    setSnackBarMessage('Exam is already added');
                } else {
                    setSnackBarMessage('Failed to create exam result. Please try again.');
                }
            });
    };

    const handleExamInput = (exam_code) => {
        try {
            // verify exam using exam code using axios use route /verify_exam/<exam_code> where exam_code is the input
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify_exam/${exam_code}`).then((res) => {
                console.log(res.data);
                console.log("EXAM VERIFIED, EXAM ID: ", res.data.exam_id);
                try {
                    handleCreateExamResult(res.data.exam_id);
                } catch(e) {
                    console.error("ERROR ON CREATING EXAM RESULT", e);
                    // handleOpenModal();
                    // exit
                    return;
                }
                
             
            }).catch((error) => {
                console.error(error);
            });
        } catch(e) {
            console.error(e);
        }
    }

    if (loading) {
        return <Loading />; // Render a loading state while fetching data
    }


    return(
        <div className="flex flex-col min-h-screen w-screen bg-body">
            <Header />
            <div className="body flex flex-col w-screen h-full py-16 align-middle justify-center items-center space-y-6">
                <h1 className="flex w-full text-2xl font-bold text-maroon justify-center">
                    Welcome, {student && (student.first_name ? `${student.first_name} ${student.last_name}` : `${student.name}!`)}
                </h1>
                <h2 className="flex w-full text-md font-regular text-black justify-center">
                    Enter your exam code:
                </h2>
                <input className="exam-input rounded-md bg-white border border-light-gray px-5" 
                    style={{height:"52px", width:"739px"}}
                    placeholder='e.g. 5x4Gf8y'
                    onKeyPress={(e) => {
                        if(e.key === 'Enter') {
                            const examCode = e.target.value;
                            handleExamInput(examCode);
                            e.target.value = ''; // Clear the input field after submitting
                        }
                    }}
                    >
                </input>
                <ExamTable exams={exams} student={student}/>
            </div>
            {snackBarMessage && (
                <Snackbar
                    message={snackBarMessage}
                    duration={3000} // Customize the duration if needed
                    onClose={() => setSnackBarMessage('')}
                />
            )}
            {isUploadModalOpen && (
                <Modal onClose={() => handleCancelClick()}
                    cancelDisabed={true}
                    actionButton={'Upload'}
                    actionColor={'green-button'}
                    actionColorText={'white'}
                    onAction={handleUploadClick}
                >
                    <h1 className='text-lg font-bold text-maroon'>Upload your photo</h1>
                    <hr className='mb-6'/>
                    {imagePreviewUrl ? (
                        <div>
                            <img src={imagePreviewUrl} alt="Preview" style={{ width: 'auto', height: '100%', maxHeight:'60vh' }} />
                        </div>
                    ) : (
                        <DragDropFiles {...dragDropFilesProps} />
                    )}
                    <p className='mt-3'>Before you can take your exams, the system requires you to upload your photo for identification.</p>
                </Modal>
            )}
        </div>
    );
}

export default StudentDashboard;