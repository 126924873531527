import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ children, onClose, closeText, actionButton, onAction, actionColor, actionColorText, cancelDisabled}) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-xl w-full">
        {children}
        <div className="text-right mt-4">
          {(cancelDisabled != true) && <button onClick={onClose} className="py-2 px-4 bg-dark-gray text-white hover:brightness-90 rounded font-semibold">
            { closeText || "Close"}
          </button>}
          {actionButton && (
            <button onClick={onAction} className={`py-2 px-4 bg-${actionColor} text-${actionColorText} hover:brightness-90 rounded ml-2 font-semibold`}>
              {actionButton}
            </button>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;