import React from "react";
import { useState, useEffect } from "react";
import ExamStatus from "./ExamStatus";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "./Pagination";
import StatusFilter from "./StatusFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const ExamTable = ({exams, student, handlePending}) => {

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  
  const [page, setPage] = useState(1);
  const entriesPerPage = 10;
  const [totalPages, setTotalPages] = useState(Math.ceil(exams.length / entriesPerPage));

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleStartExam = () => {
    // window.open(`${selectedExam.exam_link}`, "_blank");
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/results`, {
      result_id: selectedExam.result_id,
      exam_status: 'In Progress' // Assuming 'completed' is the status you want to set
    })
    .then(response => {
      console.log('Exam status updated successfully', response.data);
      handleCloseModal();
      navigate(`/exam-proper/${selectedExam.result_id}`);
      // Navigate to student-dashboard here or handle success scenario
    })
    .catch(error => {
      console.error('Error updating exam status', error);
    });
    console.log("Exam started");
  };

  const handleStatusChange = (statuses) => {
    setSelectedStatuses(statuses);
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
        setSortColumn(column);
        setSortOrder("asc");
    }
  };  

  const getStatusOrder = (status) => {
    const order = {
        "Not Taken": 1,
        "In Progress": 2,
        "To Evaluate": 3,
        "Flagged": 4,
        "Safe": 5,
    };
    return order[status] || 5;
  };

  const sortedExams = [...exams].sort((a, b) => {
    if (sortColumn){
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];
      console.log(a)

      if (sortColumn === "exam_status") {
        aValue = getStatusOrder(aValue);
        bValue = getStatusOrder(bValue);
      } else {
        console.log(aValue, bValue)
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    }
    return 0;
  });
  
  const filteredExams = sortedExams.filter((exam) => {
    const matchesSearchTerm = 
      exam.title.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = selectedStatuses.length === 0 || selectedStatuses.includes(exam.exam_status);
    return matchesSearchTerm && matchesStatus;
  });
  
  useEffect(() => {
    setTotalPages(Math.max(1, Math.ceil(filteredExams.length / entriesPerPage)));
  }, [filteredExams.length]);


  const paginatedExams = filteredExams.slice((page - 1) * entriesPerPage, page * entriesPerPage);


  return (
    <>
    <div className="flex justify-between items-center mb-4 w-screen align-middle" style={{ maxWidth: "1200px" }}>
        <div className="flex items-center justify-center align-middle w-screen space-x-4">
          <input
            type="text"
            placeholder="Search exam"
            className="border border-light-gray rounded-md p-2 text-md w-full"
            style={{ maxWidth: "400px" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <StatusFilter onStatusChange={handleStatusChange} />
        </div>
      </div>
      <table className="divide-y divide-light-gray w-2/3" style={{
        maxWidth:"800px", tableLayout: "fixed"
      }}>
        <thead className="font-bold">
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold" style={{ width: "25%" }}>
              Exam Code
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
              style={{ width: "35%" }}
              onClick={() => handleSort("title")}
            >
              Title
              {sortColumn === "title" ? (
                <FontAwesomeIcon
                  icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                  className="ml-2 opacity-100"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="ml-2 opacity-25"
                />
              )}
              </th>
              <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                    style={{ width: "25%" }}
                    onClick={() => handleSort("exam_status")}
                >
                    Status
                    {sortColumn === "exam_status" ? (
                        <FontAwesomeIcon
                            icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                            className="ml-2 opacity-100"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className="ml-2 opacity-25"
                        />
                    )}
              </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4" style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-light-gray">
          {paginatedExams.map((exam) => (
            <tr key={exam.id}>
              <td className="whitespace-nowrap px-3 py-3.5 text-md font-medium text-dark-gray" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {exam.exam_code}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {exam.title}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <ExamStatus examStatus={exam.exam_status} />
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray">
                {exam.exam_status === "Not Taken" && (
                  <button className="bg-gold hover:bg-yellow-400 text-black rounded-md py-0.5 px-4 text-sm font-medium"
                    onClick={() => { 
                      if (!(student.user_status === "pending")) {
                        setSelectedExam(exam);
                        handleOpenModal();
                      } else {
                        handlePending();
                      }
                    }}
                  >
                    Take Exam
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        {isModalOpen && (
          <Modal
            onClose={handleCloseModal}
            closeText="Cancel"
            actionButton="Start Exam"
            onAction={handleStartExam}
            actionColor="maroon"
            actionColorText="white"
          >
            <h2 className="text-lg font-semibold">Would you like to begin your exam?</h2>
            <hr className="my-4"/>
            <p className="mt-2 mb-6">
              Upon clicking "Start Exam," you consent to the collection and analysis of your webcam data for cheating detection purposes. Your privacy is important to us; all data will be handled securely and only used for exam integrity.
            </p>
          </Modal>
        )}
      </table>
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </>
  );
}

export default ExamTable;
