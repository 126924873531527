import { useRef } from "react"
import { TbDragDrop } from "react-icons/tb";

export default function DragDropFiles({ handleDrop, handleUpload, files }) {
    const inputRef = useRef();

    function handleClick(event){
        event.preventDefault();
        inputRef.current.click();
    }

    function handleDrag(event) {
        event.preventDefault();
    }

    function modifiedHandleUpload(event) {
        const file = event.target.files[0];
        if (file && (file.type === "image/jpeg" || file.type === "image/jpg")) {
            handleUpload(event); // Proceed if file is JPEG/JPG
        } else {
            alert("Only JPEG/JPG files are allowed.");
        }
    }

    function modifiedHandleDrop(event) {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && (file.type === "image/jpeg" || file.type === "image/jpg")) {
            handleDrop(event); // Proceed if file is JPEG/JPG
        } else {
            alert("Only JPEG/JPG files are allowed.");
        }
    }

    return (
        <div className="w-full">
            <div className="w-full rounded-lg border border-dashed border-maroon bg-slate-50 my-6 py-4">
                <div className="p-10 text-center w-full flex flex-col items-center space-y-2"
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <TbDragDrop />
                    <input type="file" accept="image/jpeg,image/jpg" 
                      onChange={modifiedHandleUpload} // Use modified handleUpload
                      hidden ref={inputRef} />
                    <p>Drop your image here or <button onClick={handleClick}><b className="text-maroon">browse</b></button>.</p>
                    <p className="text-gray-500">Maximum size: 1 MB</p>
                </div>
                {
                    files && 
                    <div className="p-2 flex flex-col justify-center">
                        {/* { console.log("i'm here")} */}
                        <div className="text-center">
                            <p>You uploaded:
                            {
                                Array.from(files).map((item, index) => {
                                    return (
                                        <span key={index}> {item.name}</span>
                                    )
                                })
                            }
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}